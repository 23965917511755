export interface ElectionState {
  allElections: Election[];
  current_election_id: string;
}

export enum ElectionActions {
  UPDATE_ALL_ELECTIONS = "UPDATE_ALL_ELECTIONS",
  UPDATE_CURRENT_ELECTION_ID = "UPDATE_CURRENT_ELECTION_ID",
}

export interface Election {
  id: string;
  name: string;
  start_date: string;
  end_date: string;
  page_link: string;
  status: "ACTIVE" | "INACTIVE";
  is_editable: boolean;
  created_at: string;
}

export interface CreateElectionPayload {
  name: string;
  start_date: string;
  end_date: string;
}

export type UpdateElectionPayload = Partial<CreateElectionPayload>;
