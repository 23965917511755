export interface AccountState {
  isAuthenticated: boolean;
  profile: {
    id: string;
    name: string;
    email: string;
    phone_number: string;
    is_verified: boolean;
    created_at: string;
  };
}

export enum AccountActions {
  UPDATE_IS_AUTHENTICATED = "UPDATE_IS_AUTHENTICATED",
  UPDATE_PROFILE = "UPDATE_PROFILE",
  CLEAR_PROFILE = "CLEAR_PROFILE",
}
