import { ActionType } from "../types";
import { ElectionActions, ElectionState } from "./types";

const initialState: ElectionState = {
  allElections: [],
  current_election_id: "",
};

const electionReducer = (
  state: ElectionState = initialState,
  action: ActionType
): ElectionState => {
  switch (action.type) {
    case ElectionActions.UPDATE_ALL_ELECTIONS: {
      return {
        ...state,
        allElections: action.payload,
      };
    }
    case ElectionActions.UPDATE_CURRENT_ELECTION_ID: {
      return {
        ...state,
        current_election_id: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default electionReducer;
