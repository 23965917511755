const windmillTheme = {
  button: {
    primary: {
      base: "bg-primary text-white",
      active: "bg-primary text-white",
    },
  },
  input: {
    active: "focus:box",
  },
  // backdrop: {
  //   base: "bg-opacity-75",
  // },
};

export default windmillTheme;
