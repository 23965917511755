export interface CandidateState {
  allCandidates: Candidate[];
}

export enum CandidateActions {
  UPDATE_ALL_CANDIDATES = "UPDATE_ALL_CANDIDATES",
}

export interface Candidate {
  id: string;
  name: string;
  bio?: string;
  profile_picture: string;
  alias?: string;
  created_at: string;
  election_id: {
    id: string;
    name: string;
  };
  position_id: {
    id: string;
    name: string;
  };
}

export interface CreateCandidatePayload {
  election_id: string;
  position_id: string;
  name: string;
  alias: string;
  profile_picture: string;
}
