export interface VotersState {
  allVoters: Voter[];
}

export enum VotersActions {
  UPDATE_ALL_VOTERS = "UPDATE_ALL_VOTERS",
}

export interface Voter {
  id: string;
  name: string;
  email: string;
  election_id: string;
  voted: boolean;
  voted_at: string;
  created_at: string;
  updated_at: string;
  voter_id: string;
}

export interface AddVotersPayload {
  name: string;
  file: any;
  election_id: string;
}
