import { ActionType } from "../types";
import { PositionActions, PositionState } from "./types";

const initialState: PositionState = {
  allPositions: [],
};

const positionReducer = (
  state: PositionState = initialState,
  action: ActionType
): PositionState => {
  switch (action.type) {
    case PositionActions.UPDATE_ALL_POSITIONS: {
      return {
        ...state,
        allPositions: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default positionReducer;
