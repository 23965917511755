import { TypedUseSelectorHook, useSelector } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import logger from "redux-logger";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./root-reducer";
import { persistStore, persistReducer } from "redux-persist";

let middlewares: any[] = [thunkMiddleware];

const bindMiddleware = (middleware: any) => {
  if (process.env.NODE_ENV !== "production") {
    const { composeWithDevTools } = require("redux-devtools-extension");
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const storage = require("redux-persist/lib/storage").default;

const persistConfig: any = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const makeStore = () => {
  if (process.env.NODE_ENV !== "production") {
    middlewares.push(logger);
  }

  let store: any = createStore(persistedReducer, bindMiddleware(middlewares));
  let persistor = persistStore(store);
  return { store, persistor };
};

export const { store, persistor } = makeStore();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export type RootState = ReturnType<typeof rootReducer>;

export const clearReduxStorage = () => {
  persistor.purge();
};
