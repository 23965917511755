export interface PositionState {
  allPositions: Position[];
}

export enum PositionActions {
  UPDATE_ALL_POSITIONS = "UPDATE_ALL_POSITIONS",
}

export interface Position {
  id: string;
  election_id: string;
  name: string;
  description: string;
  created_at: string;
}

export interface CreatePositionPayload {
  name: string;
  description?: string;
  election_id: string;
}
