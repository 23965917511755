import { ActionType } from "../types";
import { VotersActions, VotersState } from "./types";

const initialState: VotersState = {
  allVoters: [],
};

const votersReducer = (
  state: VotersState = initialState,
  action: ActionType
): VotersState => {
  switch (action.type) {
    case VotersActions.UPDATE_ALL_VOTERS: {
      return {
        ...state,
        allVoters: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default votersReducer;
