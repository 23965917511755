import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./assets/css/tailwind.output.css";
import App from "./App";
import { SidebarProvider } from "./context/SidebarContext";
import ThemedSuspense from "./components/ThemedSuspense";
import { Windmill } from "@windmill/react-ui";
// import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { persistor, store } from "./redux";
import { PersistGate } from "redux-persist/integration/react";
import { SnackbarProvider } from "notistack";
import { GlobalStyles } from "./styles/global-style";
import { ThemeProvider } from "styled-components";
import theme from "./styles/theme";
import windmillTheme from "./styles/windmill";

// if (process.env.NODE_ENV !== 'production') {
//   const axe = require('react-axe')
//   axe(React, ReactDOM, 1000)
// }

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <SidebarProvider>
        <Suspense fallback={<ThemedSuspense />}>
          <Windmill dark={false} theme={windmillTheme}>
            <ThemeProvider theme={theme}>
              <SnackbarProvider
                style={{ width: "100%" }}
                maxSnack={3}
                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                autoHideDuration={1500}
              >
                <GlobalStyles />
                <App />
              </SnackbarProvider>
            </ThemeProvider>
          </Windmill>
        </Suspense>
      </SidebarProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
