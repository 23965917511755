import { combineReducers } from "redux";
import accountReducer from "./account/reducer";
import candidateReducer from "./candidates/reducer";
import electionReducer from "./election/reducer";
import positionReducer from "./positions/reducer";
import votersReducer from "./voters/reducer";

const appReducer = combineReducers({
  account: accountReducer,
  election: electionReducer,
  voters: votersReducer,
  positions: positionReducer,
  candidates: candidateReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "CLEAR_ALL_STATE") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
