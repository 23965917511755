const theme = {
  colors: {
    primary: "#1e7cf5",
    primary2: "#02073E",
    secondary: "#F5A623",
    accent: "#a8affc",

    input: {
      text: "#333",
      placeholder: "#bdbdbd",
      label: "#212b36",
    },
    border: {
      input: "#c4cdd5",
      shadowColor: "#6bc9ff",
    },
  },
};

export default theme;
