import { ActionType } from "../types";
import { CandidateState, CandidateActions } from "./types";

const initialState: CandidateState = {
  allCandidates: [],
};

const candidateReducer = (
  state: CandidateState = initialState,
  action: ActionType
): CandidateState => {
  switch (action.type) {
    case CandidateActions.UPDATE_ALL_CANDIDATES: {
      return {
        ...state,
        allCandidates: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default candidateReducer;
