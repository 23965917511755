import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTypedSelector } from "./redux";

const PrivateRoutes = ({ children }: any) => {
  const location = useLocation();
  const history = useHistory();
  const next = location.pathname;

  const { isAuthenticated } = useTypedSelector((state) => state.account);

  if (isAuthenticated) {
    return <>{children}</>;
  } else {
    if (next && next !== "/") {
      history.push(`/login?next=${next}`);
    } else {
      history.push(`/login`);
    }
    return null;
  }
};

export default PrivateRoutes;
